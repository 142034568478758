import React from "react"

const NotFoundPage = () => {
  return (
    <div>
      404 Page Not Found
    </div>
  )
}

export default NotFoundPage
